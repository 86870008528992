.LoginPage {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  font-family: Arial, Helvetica, sans-serif;
  /* margin-top: 25%; */
}

.App {
  width: 100vw;
  height: 100vh;
}

body {
  margin: 0;
  padding: 0;
}

/* Sidebar */
.sidebar {
  position: fixed !important;
  top: 45px;
  bottom: 0;
  left: 0;
  /*
  padding: 58px 0 0;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 5%), 0 2px 10px 0 rgb(0 0 0 / 5%);
   */
  width: 240px;
  z-index: 600;
}

#sidebarMenu {
  background-color: #2f4050;
}

.sidebar-ar {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 58px 0 0;
  /* Height of navbar */
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 5%), 0 2px 10px 0 rgb(0 0 0 / 5%);
  width: 240px;
  z-index: 600;
}

@media (max-width: 991.98px) {
  .sidebar {
    width: 100%;
  }
}

.sidebar .active {
  border-radius: 5px;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
  background-color: rgb(82, 155, 219) !important;
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto;
  /* Scrollable contents if viewport is shorter than content. */
}

/*Barmajiyat - This will fix the responsiveness of the top menu at the end*/
.navbar-nav .dropdown-menu {
  position: absolute;
}

@media (min-width: 991.98px) {
  main {
    padding-left: 240px;
  }
}

select.view-mode {
  appearance: none;
  background-image: none;
}

textarea.view-mode  {
  resize: none;
}

 input.view-mode,textarea.view-mode,select.view-mode {
  border: none;
  border-width: 0;
}
